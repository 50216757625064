@import "../../common.scss";
.header {
  position: fixed;
  // height: 7.8em;
  // height: 9.8em;
  top: 0;
  left: 0;
  z-index: 6;
  width: 100%;
  background: transparent;
  // transition: 0.5s all ease-in-out;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  .ticker_wrap {
    width: 100%;
    background-color: $color-0099FF;
    .ticker {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include textStyles(1.125em, 1.5em, 0px);
      font-weight: 500;
      color: $color-FFFFFF;
      .title {
        padding: 0.5em 0;
      }
      .contact_email_wrap {
        display: flex;
        .call_icon_anime {
          margin-right: 0.5em;
          max-width: 1.5em;
          object-fit: contain;
          text-decoration: blink;
          animation-name: ticker;
          animation-duration: 0.2s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          animation-direction: alternate;
          -webkit-animation-name: ticker;
          -webkit-animation-duration: 0.3s;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-timing-function: ease-in-out;
          -webkit-animation-direction: alternate;
        }
        .mail_icon {
          margin-left: 1em;
          max-width: 1.75em;
        }
        .contact_no {
          color: $color-FFFFFF;
          padding: 0.5em 1em 0.5em 0.25em;
          border-right: 1px solid #fff;
        }
        .email {
          color: $color-FFFFFF;
          padding: 0.5em 1em 0.5em 0.25em;
        }
      }
    }
  }
  .nav_icon {
    width: 2.188em;
    height: 2.188em;
    position: relative;
    z-index: 2;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: flex-end;
    transform: scale(1.25);
    span {
      display: block;
      width: 100%;
      height: 0.2em;
      // background-color: #fff;
      background-color: #000;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
    }
    span:nth-child(2) {
      width: 80%;
    }
    &.open_nav {
      transform: translateY(-0.5em);
      transform: scale(1.25);
    }
    &.open_nav span:nth-child(1) {
      top: 18px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: translateY(1.2em) rotate(135deg);
    }

    &.open_nav span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    &.open_nav span:nth-child(3) {
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: translateY(-0.28em) rotate(-135deg);
    }
  }
  .nav_icon_white {
    span {
      background-color: #fff;
    }
    &.open_nav {
      span {
        background-color: #000;
      }
    }
  }
  .route_link {
    color: $color-FFFFFF;
    text-decoration: none;
    transition: 0.3s all;
    padding-top: 10px;

    position: relative;
    &:before {
      content: "";
      position: absolute;
      transition: transform 0.5s ease;
      left: 0;
      right: 0;
      bottom: 0;
      width: 75%;
      margin: 0 auto;
      height: 2px;
      background: $color-0099FF;
      transform: scaleX(0);
    }
    &:hover:before {
      transform: scaleX(1);
      color: $color-0099FF;
    }
  }
  .route_link_black {
    color: #000 !important;
    text-decoration: none;
    transition: 0.3s all;
    &:hover {
      color: $color-0099FF !important;
    }
  }
  .contact_btn {
    background-color: $color-0099FF;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    .route_link {
      color: $color-FFFFFF;
      &:hover:before {
        transform: scaleY(0);
        color: $color-FFFFFF;
      }
    }
  }
  .link_selected {
    font-weight: 600;
    text-decoration: underline;
  }

  .Navbarclass {
    position: relative;
    display: flex;
    // height: 80px;
    width: 100%;
    padding: 1em 0;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0.5em 0;
    border: none;
    text-decoration: none;
    outline: none;
    &:hover {
      cursor: default;
    }
    .nav_logo_img {
      width: fit-content;
      // max-width: 8.188em;
      max-width: 12.5em;
      position: absolute;
      left: 0%;
      top: 50%;
      transform: translate(0%, -50%);
      padding: 0;
      height: 100%;
      text-align: left;
      object-fit: contain;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      outline: none;
      border: none;
      margin: 0;
      transition: all 0.3s ease;
      .dropdown {
        .dropdown-menu {
          opacity: 0;
          display: block;
          pointer-events: none;
          margin: 0;
        }
        &:hover {
          .dropdown-menu {
            opacity: 1;
            padding: 1em 2em 1em 1em;
            pointer-events: all;
            transition: all 0.2s ease-in-out;
          }
        }
        .dropdown-item {
          background: transparent;
          @include textStyles(0.938em, 1.5em, 0px);
          font-weight: 600;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            transition: transform 0.5s ease;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $color-0099FF;
            transform: scaleX(0);
          }
          &:hover:before {
            // transform: scaleX(1);
            color: $color-0099FF;
          }
        }
        .ourprod_link {
          pointer-events: none;
        }
        &:hover {
          .down_arrow {
            transform: rotate(-90deg);
          }
        }
        .down_arrow {
          margin-left: 1em;
          transition: 0.3s;
        }
      }
    }
    ul li {
      margin: 0;
      // margin: 0 5px;
    }
    ul li a {
      @include textStyles(1.25em, 1.5em, 0px);
      font-family: "PT Sans", sans-serif;
      color: $color-FFFFFF;
      font-weight: bolder;
      text-decoration: none;
      text-transform: capitalize;
      outline: none;
      // font-size: 18px;
      font-weight: 600;
      padding: 0.5em 0.938em;
      border-radius: 5px;
      transition: all 0.3s ease;
    }
    ul li a.active,
    ul li a:hover {
      // color: $color-FFFFFF;
      opacity: 1;
    }
    ul li a:focus {
      outline: none;
    }
    .catalogue_btn {
      background-color: $color-0099FF;
      padding: 1em 2em;
      border-radius: 50px;
      border: none;
      .route_link:hover {
        color: #fff;
      }
    }
    .menu-btn i {
      color: $color-000000;
      font-size: 22px;
      cursor: pointer;
      display: none;
    }
    input[type="checkbox"] {
      display: none;
    }
  }
}
.navbar_bg {
  animation: 0.3s opacityAnim ease-in-out;
  // background-color: #171717;
  background-color: #f9f9f9;
  box-shadow: 0px 5px 15px -10px #111;

  .Navbarclass {
    ul {
      li {
        a {
          color: #000;
        }
        a.active {
          color: #000;
        }
        .dropdown {
          a {
            color: #000;
          }
        }
      }
      .contact_btn a.active {
        color: $color-FFFFFF;
      }
    }
  }
}
/*============================
    Desktop CSS Starts Here   
=============================*/

// @media(min-width:  1280px) {}
// @media (max-width: 1536px) {}
// @media (width: 1536px) {}
// @media (max-width: 1440px) {}
// @media(max-width: 1280px) {}
/*============================
Desktop CSS Ends Here   
=============================*/

/*================================
Ipad/Mobile CSS Starts Here   
================================*/
// @media(max-width: 1194px) {}
// @media (max-width: 1024px) {}
@media (max-width: 1023px) {
  .header {
    padding: 0;
    .ticker_wrap {
      z-index: 9;
    }
    .mb_nav_flex {
      min-height: 6.5em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .mb_nav_logo_img {
        width: 100%;
        max-width: 12em;
        position: relative;
        z-index: 5;
        padding: 0.5em;
      }
    }
    .mb_menu {
      position: fixed;
      top: 0;
      left: -150%;
      width: 100%;
      height: 100vh;
      // background-color: #171717;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      transition: 0.3s all;
    }
    .mb_menu.mb_menu_active {
      left: 0;
    }
    .mb_links_wrapper_cont {
      padding-top: 5em;
      height: 65em;
      width: 100%;
      overflow-y: scroll;
      .catalogue_btn {
        background-color: $color-0099FF;
        padding: 0.8em 2em;
        border-radius: 50px;
        border: none;
        font-size: 1.6em;
        .route_link:hover {
          color: #000;
        }
      }
    }
    .header-socialmedia-icon {
      position: relative;
      width: 100%;
      max-width: 100%;
      text-align: center;
      background-color: $color-FFFFFF;
      .socialmedia-icon {
        width: 100%;
        max-width: 1.8em;
        margin-right: 3em;
      }
      .socialmedia-icon-1 {
        max-width: 1.4em;
      }
      .header-contact {
        display: inline-block;
        font-size: 1.5em;
        margin-top: 0.75em;
        color: $color-000000;
      }
      .header-call-icon {
        vertical-align: initial;
        margin-right: 1em;
        width: 100%;
        max-width: 1.75em;
      }
      .header-mail {
        display: inline-block;
        font-size: 1.5em;
        margin-top: 0.4em;
        color: $color-000000;
      }
      .header-mail-icon {
        vertical-align: initial;
        margin-right: 1em;
        width: 100%;
        max-width: 2em;
      }
    }
    .mb_links_wrapper_cont::-webkit-scrollbar {
      display: none;
    }
    .mb_link_wrapper {
      .mb_route_text {
        @include textStyles(1.6em, 1.905em, 0);
        text-transform: capitalize;
        // color: $color-FFFFFF;
        color: #000;
        font-weight: bolder;
      }
      .mb_sub_route_text {
        @include textStyles(1.5em, 1.905em, 0);
        text-align: left;
        text-transform: capitalize;
        opacity: 0.8;
        color: $color-000000;
        font-weight: 600;
        .sub_arrow {
          margin-left: 1em;
          width: 100%;
          max-width: 1em;
        }
      }
      .mb_link_main_wrapper {
        position: relative;
        width: fit-content;
        margin: 0em 1.5em 1.8em;
        display: flex;
        align-items: center;
        .plus_minus_span {
          // position: absolute;
          // top: 40%;
          // transform: translateY(-50%);
          // right: -1em;
          margin-left: 0.2em;
          color: #000;
          font-size: 2.5em;
          font-weight: 600;
        }
      }
      .mb_sub_menu_wrapper {
        display: none;
        .mb_sub_link_wrapper {
          padding-bottom: 1.5em;
        }
      }
      .mb_sub_menu_wrapper.sub_menu_active {
        display: block;
        text-align: left;
        margin: 0em 1.5em 1em;
      }
    }
  }

  .header.navbar_bg {
    .mb_nav_flex {
      .mb_nav_logo_img {
        // background-color: #000;
        padding: 0.5em;
      }
    }
  }
}

@media (max-width: 834px) {
  .header {
    .ticker_wrap {
      .ticker {
        font-size: 0.75em;
      }
    }

    .mb_links_wrapper_cont {
      width: 100%;
      max-width: 747.125px;
    }
    // .header-socialmedia-icon {
    // max-width: 790.125px;
    // left: 55%;
    // bottom: 15%;
    // }
  }
}

@media (max-width: 600px) {
  .header {
    .ticker_wrap {
      display: none;
    }
    .mb_links_wrapper_cont {
      height: 58em;
    }
    .header-socialmedia-icon {
      // bottom: 20%;
      text-align: center;
      // left: 50%;
      right: auto;
      top: auto;
      margin: 0 auto;
      padding: 0 1em;
      .title,
      .header-contact,
      .header-mail {
        font-size: 1.5em;
      }
    }
  }
}

@media (max-width: 365px) {
  .header {
    .mb_links_wrapper_cont {
      height: 50em;
    }
    .header-socialmedia-icon {
      bottom: 14%;
    }
  }
}

// @media(max-width: 834px) {}
// @media(max-width: 767px) {}
// @media(max-width: 375px) {}
// @media(max-width: 320px) {}
/*================================
Ipad/Mobile CSS Ends Here   
================================*/
/*
This is the extracted copied part responsible for showing & hiding the submenu.
*/
@keyframes opacityAnim {
  from {
    background-color: transparent;
  }
  to {
    background-color: #f9f9f9;
  }
}
@keyframes ticker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.call_icon_anime {
  text-decoration: blink;
  animation-name: ticker;
  animation-duration: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  -webkit-animation-name: ticker;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}
