@import "../../common.scss";

.listing_sec1 {
  padding: 6em 0;
  .category_text_container {
    text-align: left;
    margin-bottom: 4em;
    .title {
      @include textStyles(5em, 1.2em, 0);
      font-weight: 600;
      color: $color-000000;
      text-transform: capitalize;
      margin: 0em 0 0.25em;
      font-family: "PT Sans", sans-serif;
      text-align: center;
    }
    .desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em 0;
    }
  }
  .products_wrapper {
    .product_box {
      display: flex;
      box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px,
        rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px,
        rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
      border-radius: 15px;
      margin: 0 0 6em;
      .product_img {
        width: 100%;
        height: 100%;
        transform: scale(1);
        transition: 0.3s ease-in-out;
      }

      .imageanimate {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 15px;
        &:hover {
          .product_img {
            transform: scale(1.2);
            // filter: blur(0);
          }
        }
      }
      .product_details {
        padding: 3em 1em;
        .title {
          @include textStyles(3.5em, 1.2em, 0);
          font-weight: 600;
          color: $color-000000;
          text-transform: capitalize;
          margin: 0em 0 0.25em;
          font-family: "PT Sans", sans-serif;
        }
        .desc {
          @include textStyles(1.5em, 1.5em, 0.24px);
          margin: 1em 0;
        }
        .features {
          @include textStyles(1.5em, 1.2em, 0);
          font-weight: 600;
          color: $color-000000;
          text-transform: capitalize;
          font-family: "PT Sans", sans-serif;
        }
        .feature_wrap {
          .feature {
            @include textStyles(1.5em, 1.5em, 0.24px);
            margin: 0.25em 0;
          }
        }
      }
    }
  }
  .category_bottom_text_container {
    .title {
      @include textStyles(3.5em, 1.2em, 0);
      font-weight: 600;
      color: $color-000000;
      text-transform: capitalize;
      margin: 0em 0 0.5em;
      font-family: "PT Sans", sans-serif;
      text-align: center;
    }
    .desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em 0;
    }
    .core_wrap {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1.5em;
      .core_point {
        flex-basis: 50%;
        padding: 0 2em 1em 0;
        list-style-type: disc;
        // list-style-position: inside;
        @include textStyles(1.5em, 1.5em, 0.24px);
      }
    }
  }
}

@media (max-width: 992px) {
  .listing_sec1 {
    .category_text_container {
      .desc {
        font-size: 1.8em;
      }
    }
    .products_wrapper {
      .product_box {
        margin: 0 0 10em;
        .imageanimate {
          text-align: center;
        }
        .product_img {
          max-width: 40em;
          margin: 0 auto;
          border-radius: 15px;
        }
        .product_details {
          .desc {
            font-size: 1.8em;
          }
          .features {
            font-size: 1.8em;
          }
          .feature_wrap {
            .feature {
              font-size: 1.8em;
            }
          }
        }
      }
    }
    .category_bottom_text_container {
      .desc {
        font-size: 1.8em;
      }
      .core_wrap {
        padding: 0 3em;
        .core_point {
          font-size: 1.8em;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .listing_sec1 {
    .category_text_container {
      .title {
        font-size: 3.5em;
      }
    }
    .products_wrapper {
      .product_box {
        margin: 0 0 6em;
        .product_details {
          .title {
            font-size: 2.5em;
          }
        }
      }
    }
    .category_bottom_text_container {
      .title {
        font-size: 2.5em;
      }
      .core_wrap {
        padding: 0 0 0 3em;
        .core_point {
          padding: 0 0 1em 0;
          width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
}
