@import "../../common.scss";

.contact_sec2 {
  // margin-top: 4.75em;
  padding-bottom: 5em;
  .banner {
    position: relative;
    width: 100%;
    min-height: 9em;
    max-height: 9em;
    background-color: $color-0099FF;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color-000000;
      width: 100%;
      height: 100%;
      // min-height: 100vh;
      //   max-height: 100vh;
      transition: 0.3ms ease-in-out;
      opacity: 0.5;
      z-index: 1;
    }
    .breadcrumb_wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      text-align: center;
      width: 100%;
      z-index: 2;
    }
    .common_breadcrumb_link {
      @include textStyles(1.5em, 0.842em, 1.6px);
      @include fontStyles("Poppins", 400);
      color: #fff;
      opacity: 1;
      text-decoration: none;
      text-transform: capitalize;
      letter-spacing: 0px;
    }
    .common_breadcrumb_line2 {
      @include textStyles(3.5em, 1.175em, 0.45px);
      @include fontStyles("Poppins", 600);
      margin-top: 0.5em;
      text-align: center;
      color: #ffffff;
      text-transform: capitalize;
      opacity: 0.9;
    }
  }
  .text_container {
    position: relative;
    .heading {
      margin-top: 0.5em;
      max-width: 100%;
      @include textStyles(5em, 1.2em, -1px);
      font-family: "PT Sans", sans-serif;
      font-weight: 600;
      text-align: center;
      text-transform: capitalize;
      br {
        display: none;
      }
    }
    .desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      max-width: 70%;
      margin: 0.7em auto 0;
      text-align: center;
      font-family: "Maven Pro", sans-serif;
    }
  }
  .box_container {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 2em;
    border-radius: 3px;
    transition: all 0.3s ease;
    &:hover {
      transition: all 0.3s ease;
      background: $color-0099FF;
      color: $color-FFFFFF;
      .number {
        transition: all 0.3s ease;
        color: $color-FFFFFF;
      }
    }
    .icon_img {
      max-width: 6.625em;
    }
    .heading {
      @include textStyles(2.5em, 1.2em, -1px);
      font-weight: 600;
      font-family: "PT Sans", sans-serif;
      text-transform: capitalize;
      margin: 0.7em auto 0;
      text-align: center;
    }
    .desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 0.7em 0 0;
      opacity: 0.95;
      text-align: center;
      font-family: "Maven Pro", sans-serif;
      a {
        text-align: center;
      }
    }
  }
}

.global_presence {
  .heading {
    margin-top: 0.5em;
    max-width: 100%;
    @include textStyles(3.5em, 1.2em, -1px);
    font-family: "PT Sans", sans-serif;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
  }
  .global_map {
    width: 100%;
    max-width: 100%;
  }
  .country_list {
    margin: 0 0 4em;
    -moz-column-count: 5;
    -webkit-column-count: 5;
    column-count: 5;
    -moz-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    .country_name {
      margin: 0.5em 0 0 1em;
      @include textStyles(1.5em, 1.5em, 0.24px);
      text-align: center;
      font-weight: 600;
      font-family: "Maven Pro", sans-serif;
    }
  }
}

.contact_sec3 {
  padding: 0;
  margin: 0;
}

@media (max-width: 992px) {
  .contact_sec2 {
    .text_container {
      .heading {
        margin-top: 1.5em;
      }
      .desc {
        max-width: 100%;
        font-size: 1.8em;
      }
    }
    .box_container {
      .desc {
        font-size: 1.8em;
      }
    }
  }
}

@media (max-width: 767px) {
  .contact_sec2 {
    // margin-top: 5.4em;
    .text_container {
      .heading {
        font-size: 4em;
        max-width: 100%;
        text-align: center;
        margin: 0.5em 0 0;
        br {
          display: block;
        }
      }
    }
    .box_container {
      margin-top: 2.4em;
      padding: 2em 1em;
      .heading {
        font-size: 2em;
        max-width: 100%;
        text-align: center;
      }
      .desc {
        font-size: 1.8em;
        margin-top: 1em;
      }
    }
  }

  .global_presence {
    .country_list {
      margin: 0 0 4em;
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
      -moz-column-gap: 15px;
      -webkit-column-gap: 15px;
      column-gap: 15px;
      .country_name {
        @include textStyles(1.8em, 1.5em, 0.24px);
      }
    }
  }
}
