@import "../../common.scss";

.inner_sec1 {
  padding: 6em 0;
  .text_container {
    text-align: left;
    .title {
      @include textStyles(5em, 1.2em, 0);
      font-weight: 600;
      color: $color-000000;
      text-transform: capitalize;
      margin: 0em 0 0.25em;
      font-family: "PT Sans", sans-serif;
      text-align: left;
    }
    .desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em 0;
      .subhead {
        @include textStyles(1.5em, 1.2em, 0);
        font-weight: 600;
        color: $color-000000;
        text-transform: capitalize;
        margin: 0em 0 0.75em;
        font-family: "PT Sans", sans-serif;
        text-align: left;
      }
      .faq_desc {
        margin: 0 0 0.75em;
      }
      ul {
        margin: 0.5em 0 0;
        li {
          list-style-type: disc;
          margin: 0.5em 0 0 1em;
        }
      }
    }
    .feature_wrap {
      .feature {
        @include textStyles(1.5em, 1.5em, 0.24px);
        margin: 0.25em 0;
      }
    }
  }
  .sticky_contact_box {
    position: sticky;
    top: 12.5em;
    padding: 5em 1.5em;
    background: $color-0099FF;
    color: $color-FFFFFF;
    border-radius: 5px;
    text-align: center;
    .logo {
      width: 100%;
      max-width: 70%;
      margin: 0 auto 1em;
    }
    .title {
      @include textStyles(3em, 1.2em, 0);
      font-weight: 600;
      color: $color-FFFFFF;
      text-transform: capitalize;
      margin: 0em 0 0.25em;
      font-family: "PT Sans", sans-serif;
      text-align: center;
    }
    .text_container {
      .heading {
        @include textStyles(2em, 1.2em, -1px);
        font-weight: 600;
        font-family: "PT Sans", sans-serif;
        text-transform: capitalize;
        margin: 0.7em auto 0;
        text-align: center;
      }
      .desc {
        @include textStyles(1.5em, 1.5em, 0.24px);
        margin: 0.7em 0 0;
        opacity: 0.95;
        text-align: center;
        font-family: "Maven Pro", sans-serif;
        a {
          text-align: center;
          color: $color-FFFFFF;
          transition: all 0.3s;
          &:hover {
            text-decoration: underline;
            border-bottom: 1px solid $color-FFFFFF;
          }
        }
      }
    }
    .cta_wrapper {
      margin: 2em auto 0;
      background: $color-FFFFFF;
      border-radius: 5px;
      &:hover {
        .common_button {
          color: $color-0099FF;
        }
        ::before {
          width: 0;
        }
      }
    }
  }
  .img_wrapper {
    text-align: right;
    .product_img {
      width: 100%;
    }
  }
  .product_gallery {
    padding: 4em 0 0;
    .gallery_img {
      width: 100%;
      border-radius: 3px;
      border-radius: 0.5em;
      margin: 2em 0 0;
      cursor: pointer;
    }
  }
  .text_container {
    .main_title {
      @include textStyles(4em, 1.2em, 0);
      text-align: center;
      padding: 0 0 1em;
    }
  }
  .documents_wrapper {
    margin: 2em 0;
    .cta_wrapper {
      max-width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .inner_sec1 {
    .text_container {
      .desc {
        font-size: 1.8em;
      }
      .feature_wrap {
        .feature {
          font-size: 1.8em;
        }
      }
    }
    .sticky_contact_box {
      .logo {
        width: 100%;
        max-width: 40%;
      }
    }
    .img_wrapper {
      margin-bottom: 3em;
    }
  }
}

@media (max-width: 767px) {
  .inner_sec1 {
    .text_container {
      .title {
        font-size: 3.5em;
      }
    }
    .sticky_contact_box {
      position: relative;
      top: auto;
      margin: 5em 0 0;
      .logo {
        width: 100%;
        max-width: 50%;
        margin: 0 auto 1em;
      }
      .text_container {
        .heading {
          @include textStyles(2em, 1.2em, -1px);
          font-weight: 600;
          font-family: "PT Sans", sans-serif;
          text-transform: capitalize;
          margin: 0.7em auto 0;
          text-align: center;
        }
        .desc {
          @include textStyles(1.5em, 1.5em, 0.24px);
          margin: 0.7em 0 0;
          opacity: 0.95;
          text-align: center;
          font-family: "Maven Pro", sans-serif;
          a {
            text-align: center;
            color: $color-FFFFFF;
            transition: all 0.3s;
            &:hover {
              text-decoration: underline;
              border-bottom: 1px solid $color-FFFFFF;
            }
          }
        }
      }
    }
  }
}
