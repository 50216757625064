@import "../../common.scss";

.common_banner {
  position: relative;
  width: 100%;
  min-height: 9em;
  max-height: 9em;
  background-color: $color-0099FF;
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    transition: height 0.5s ease-out;
    background: rgba($color: $color-000000, $alpha: 0.75);
  }
  .disabled_link {
    pointer-events: none;
  }
  .breadcrumb_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
    width: 100%;
  }
  .common_breadcrumb_link {
    @include textStyles(1.5em, 0.842em, 1.6px);
    @include fontStyles("Poppins", 400);
    color: #fff;
    opacity: 1;
    text-decoration: none;
    text-transform: capitalize;
    letter-spacing: 0px;
  }
  .common_breadcrumb_line2 {
    @include textStyles(3.5em, 1.175em, 0.45px);
    @include fontStyles("Poppins", 600);
    margin-top: 0.5em;
    text-align: center;
    color: #ffffff;
    text-transform: capitalize;
    opacity: 0.9;
  }
}

@media (max-width: 767px) {
  .common_banner {
    min-height: 7em;
    max-height: 7em;
    .common_breadcrumb_link {
      @include textStyles(1.4em, 1.5em, 1.4px);
    }
    .common_breadcrumb_line2 {
      @include textStyles(2em, 1.3em, normal);
      width: 100%;
      max-width: 80%;
      margin: 0 auto;
      margin-top: 0.4em;
      text-align: center;
    }
  }
}
