/* font imports */
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");

/*============================
    General css start Here    
============================*/

$color-FFFFFF: #ffffff;
$color-FAF9F6: #f5f5f5;
$color-000000: #000000;
$color-0099FF: #0099ff;

body {
  font-family: "Maven Pro", sans-serif;
  color: $color-000000;
}
video {
  width: 100% !important;
}
img {
  object-fit: cover;
  height: auto;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: $color-FFFFFF;
}
::-webkit-scrollbar-thumb {
  background-color: $color-0099FF;
}

::-moz-selection {
  color: var(--white);
  background: $color-0099FF;
}

::selection {
  color: var(--white);
  background: $color-0099FF;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
a {
  text-decoration: none !important;
  color: $color-000000;
  &:hover {
    color: $color-000000;
  }
}
:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}
button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}
.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
.swiper-button-next,
.swiper-button-prev {
  padding: 0 30px;
  color: $color-0099FF !important;
  fill: $color-0099FF !important;
  stroke: $color-0099FF !important;
  border-radius: 50%;
}
.swiper-pagination-bullet {
  background-color: $color-FFFFFF !important;
  opacity: 1 !important;
  width: 1em !important;
  height: 1em !important;
  transition: all 1s;
}
.swiper-pagination-bullet-active {
  background-color: $color-0099FF !important;
}
li {
  list-style-type: none;
}
[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}
.cta_wrapper {
  margin: 2em 0.5em 0.5em;
  width: 100%;
  max-width: 17em;
  a {
    transition: all 0.3s;
    color: $color-0099FF;
  }
  .common_button {
    width: 100%;
    padding: 0.5em 2em;
    border: none;
    border-radius: 5px;
    letter-spacing: 0px;
    font-weight: 500;
    background-color: transparent;
    text-transform: capitalize;
    transition: all 0.3s;
    color: $color-0099FF;
    font-size: 1rem;
    position: relative;
    overflow: hidden;
    outline: none;
    border: 1px solid $color-0099FF;
    z-index: 1;
  }

  .common_button:hover {
    color: $color-FFFFFF;
    box-shadow: 2px 2.5px 8.5px -2px $color-0099FF;
    a {
      color: $color-FFFFFF;
    }
  }

  .common_button::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: $color-0099FF;
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }

  .common_button:hover::before {
    width: 250%;
  }
}

.overlay {
  overflow: hidden;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 3px;
}

.socialmedia_float_icon {
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translate(0%, 50%);
  padding: 1em 0.25em 1em 1em;
  background-color: #fff;
  border-radius: 20px 0px 0px 20px;
  box-shadow: 0px 0px 6px #00000054;
  z-index: 9;
  .float_icon_social {
    width: 100%;
    min-width: 3em;
    min-height: 3em;
    max-width: 3em;
    margin: 1em auto;
    animation: shake 3s;
    animation-iteration-count: infinite;
  }
  .whatsapp_icon {
    max-width: 2.5em;
  }
}

.enquire {
  position: relative;
  padding: 1em 0;
  .flex_box {
    width: 100%;
    display: flex;
    .whatsappmob,
    .enquirenowicon {
      // position: fixed;
      max-width: 1.5em;
      max-height: 1.5em;
      margin-right: 1em;
      img {
        object-fit: cover;
      }
    }
    .enquiretext,
    .wtsptext {
      width: 50%;
      position: fixed;
      font-family: "PT Sans", sans-serif;
      bottom: 0;
      padding: 0.8em 0em;
      text-align: center;
      font-size: 1.4em;
      text-transform: capitalize;
      font-weight: 700;
      color: $color-FFFFFF;
      z-index: 199;
      &::after {
        border-right: 1px solid #101010;
      }
    }
    .enquiretext {
      left: 0;
      background-color: #f8c301;
    }
    .wtsptext {
      right: 0;
      background-color: #2db640;
      &::after {
        border-right: 1px solid #101010;
      }
    }
  }
}

.hyperlink {
  color: $color-0099FF;
  font-weight: 600;
  transition: 0.3ms ease-in-out;
  &:hover {
    color: $color-0099FF;
  }
}

/*============================
    General css ends Here    
============================*/

/*============================
    Common container start Here    
============================*/
@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 2426.65px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .my_container {
    max-width: 2293.3px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 2426.65px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1720px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1820px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1900px) {
  body {
    font-size: 14px;
  }
  .my_container {
    max-width: 1361.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1640.335px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1536px) {
  body {
    font-size: 12.5px;
  }
  .my_container {
    max-width: 1361.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1448.835px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1440px) {
  .my_container {
    max-width: 1290px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1365px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1367px) {
  body {
    font-size: 12px;
  }
  .my_container {
    max-width: 1223.708px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
  .gallery_container {
    max-width: 1294.854px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1146.67px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1069.625px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 992px) {
  .cta_wrapper {
    .common_button {
      font-size: 1.6em;
      color: $color-FFFFFF;
      background-color: $color-0099FF;
      a {
        color: $color-FFFFFF;
      }
    }
    .common_button::before {
      display: none;
    }
  }
}

@media (max-width: 834px) {
  body {
    font-size: 11.5px;
  }
  .my_container {
    max-width: 747.125px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
}

/*============================
    Common container end Here    
============================*/

/*============================
    Form field css start Here    
============================*/

.link_btn_nohover:hover {
  font-weight: normal;
}

.navigate_link {
  text-decoration: underline;
  color: $color-FFFFFF;
  &:hover {
    color: $color-FFFFFF;
  }
}

/*============================
    Form field css ends Here    
============================*/

.navigation_wrapper {
  .nav_arrow {
    cursor: pointer;
    user-select: none;
  }
}

.swiper-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media (max-width: 767px) {
  .enquire-now {
    position: relative;
    padding: 1em 0;
    .enquire-background {
      position: fixed;
      width: 100%;
      bottom: 0;
      z-index: 3;
    }
    .enquire-flex {
      display: flex;
      .enquire-whatsapp-icon {
        position: fixed;
        right: 0;
        bottom: 0.2em;
        z-index: 199;
      }
      .enquire-text-cta {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0.8em 3.5em;
        font-size: 1.4em;
        text-transform: uppercase;
        font-weight: 600;
        color: $color-000000;
        z-index: 199;
        &::after {
          border-right: 1px solid $color-000000;
        }
      }
    }
  }
}

/* ====================
        MIXIN start here
======================*/

@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin fontStyles($fontFamily, $fontWeight) {
  font-family: $fontFamily, "Maven Pro", sans-serif;
  font-weight: $fontWeight;
}
@mixin shadowBox {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 15px;
}

@mixin Breadcrumb {
  @include textStyles(1.125em, 1.5em, 0.18px);
  opacity: 0.5;
}

/* ====================
        MIXIN end here
======================*/

// Talha common css starts here

// Talha common css ends here

// Tanmay common css starts here

// Tanmay common css ends here

// Sachin common css starts here

// Sachin common css ends here

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
