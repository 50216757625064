@import "../../common.scss";

.terms_sec1 {
  padding: 2.5em 0 5em;
  .text_container {
    padding: 0 5em 0;
    .title {
      @include textStyles(3.5em, 1.2em, -1px);
      font-weight: 600;
      font-family: "PT Sans", sans-serif;
      margin: 1em 0 0;
    }
    .description {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin-top: 2em;
    }
  }
}

@media (max-width: 992px) {
  .terms_sec1 {
    .text_container {
      .description {
        font-size: 1.8em;
      }
    }
  }
}

@media (max-width: 767px) {
  .terms_sec1 {
    .text_container {
      padding: 5em 0 0;
      .title {
        font-size: 2.5em;
        max-width: 100%;
      }
      .description {
        font-size: 1.8em;
        margin-top: 1em;
      }
      .cta_wrapper {
        margin-top: 3em;
      }
    }
  }
}

@media (max-width: 600px) {
  .terms_sec1 {
    .text_container {
      padding: 1em 0 0;
    }
  }
}
