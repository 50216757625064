@import "../../common.scss";
.footer {
  padding-top: 6.625em;
  padding-bottom: 1.875em;
  background-color: $color-0099FF;
  position: relative;
  .footeruparrow {
    position: absolute;
    top: -2.2em;
    right: 6.438em;
    width: 100%;
    max-width: 4.125em;
    cursor: pointer;
    transform: rotate(-90deg);
    z-index: 1;
    filter: drop-shadow(0 0px 10px $color-FFFFFF);
  }
  .footer_first_row {
    // padding-bottom: 2em;

    .logo_container {
      margin-top: -4em;
    }
    .certificate {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      .iso_certified {
        width: 100%;
        height: 100%;
        max-width: 15em;
        object-fit: contain;
      }
    }
  }
  .social_links_wrapper {
    margin-top: 1.625em;
  }
  .social_img {
    width: 100%;
    max-width: 2.146em;
    &:hover {
      transform: scale(1.1);
    }
  }
  .social_link {
    margin-left: 1.242em;
  }
  .social_link:nth-child(1) {
    margin-left: 0;
  }
  .footer_logo {
    width: 100%;
    max-width: 10em;
  }
  .contact_info {
    margin-top: 2em;
    .contact_title {
      @include textStyles(1.25em, 1.167em, 0.9px);
      @include fontStyles("Work Sans", 500);
      text-transform: uppercase;
      color: $color-FFFFFF;
    }

    .info {
      margin-top: 1em;
      display: flex;
      align-items: center;

      .call_icon {
        width: 100%;
        height: 100%;
        max-width: 2.5em;
        margin-right: 0.5em;
        img {
          width: 100%;
          height: 100%;
          max-width: 1.5em;
        }
      }
      .mail_icon {
        width: 100%;
        height: 100%;
        max-width: 2.5em;
        margin-right: 0.5em;
        img {
          width: 100%;
          height: 100%;
          max-width: 1.75em;
        }
      }
      .catalogue_icon {
        width: 100%;
        height: 100%;
        max-width: 2.5em;
        margin-right: 0.5em;
        img {
          width: 100%;
          height: 100%;
          max-width: 2em;
        }
      }
      .text {
        .number a {
          @include textStyles(1.125em, 1.875em, 0.8px);
          @include fontStyles("Work Sans", 500);
          text-transform: initial;
          color: $color-FFFFFF;
          opacity: 0.8;
          text-decoration: none;
          &:hover {
            font-weight: 700;
            opacity: 1;
          }
        }
      }
    }
    .social_flex {
      display: flex;
      margin: 2em 0 0;
      .social_icon {
        width: 100%;
        height: 100%;
        max-width: 2.5em;
        margin-right: 1.5em;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          max-width: 2.5em;
        }
      }
    }
  }
  .box_title {
    @include textStyles(1.25em, 1.167em, 0.9px);
    @include fontStyles("Work Sans", 500);
    text-transform: uppercase;
    color: $color-FFFFFF;
  }
  .box_content_wrapper {
    color: $color-FFFFFF;
    margin-top: 1em;
  }
  .link_name_wrapper {
    margin-top: 0.9em;
  }
  .link_name {
    @include textStyles(1.125em, 1.875em, 0.8px);
    @include fontStyles("Work Sans", 500);
    text-transform: initial;
    color: $color-FFFFFF;
    opacity: 0.8;
    text-decoration: none;
    &:hover {
      font-weight: 700;
      opacity: 1;
    }
  }
  .footer_contact_title {
    @include textStyles(1.125em, 1.167em, 0.9px);
    @include fontStyles("Work Sans", 500);
    text-transform: uppercase;
    color: $color-FFFFFF;
    margin-top: 1.5em;
  }
  .footer_contact_detail_box:nth-child(1) .footer_contact_title {
    margin-top: 0;
  }
  .footer_contact_desc {
    @include textStyles(1em, 1.875em, 0.8px);
    @include fontStyles("Work Sans", 500);
    margin-top: 1em;
    color: $color-FFFFFF;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .footer_contact_desc a {
    color: $color-FFFFFF;
    text-decoration: none;
    &:hover {
      font-weight: 700;
      opacity: 1;
    }
  }
  .footer_second_row {
    border-top: 1px solid $color-0099FF;
    padding-top: 2em;

    .footer_second_content {
      .second_row_content {
        color: $color-FFFFFF;
        font-size: 1.25em;
        a {
          color: $color-FFFFFF;
        }
      }
    }

    .social_icons {
      display: flex;
      align-items: flex-end;
      .footer_icon {
        margin-right: 3em;
        img {
          object-fit: contain;
          width: 100%;
          min-width: 2em;
          max-height: 2.5em;
          max-width: 2em;
        }
      }
    }
    .copyright_text {
      color: $color-FFFFFF;
      a {
        color: $color-FFFFFF;
        font-size: 1.1em;
      }
      a:hover {
        font-weight: bold;
      }
    }
  }
}
/*============================
    Desktop CSS Starts Here   
=============================*/

// @media(min-width:  1280px) {}
// @media (min-width: 2560px) {
//   .footer {
//     padding-bottom: 2.875em;
//   }
// }
// @media(max-width: 1919px) {}
// @media(max-width: 1536px) {}
// @media(max-width: 1440px) {}
// @media(max-width: 1366px) {}
// @media(max-width: 1280px) {}
/*============================
Desktop CSS Ends Here   
=============================*/

/*================================
Ipad/Mobile CSS Starts Here   
================================*/
// @media(max-width: 1194px) {}
// @media(max-width: 1024px) {}
@media (max-width: 1023px) {
  .footer {
    padding-top: 4.4em;
    padding-bottom: 1.8em;
    .footeruparrow {
      max-width: 4em;
      top: -2em;
      right: 1.4em;
    }
    .contact_info {
      margin-top: 0;
      p {
        font-size: 1.7em;
      }
      .info {
        .text {
          font-size: 1.5em;
        }
        .icon {
          width: auto;
          margin-right: 1em;
        }
        .call_icon {
          max-width: 3.5em;

          img {
            max-width: 3.5em;
          }
        }
        .mail_icon {
          max-width: 3.5em;

          img {
            max-width: 2em;
          }
        }
        .catalogue_icon {
          max-width: 3.5em;

          img {
            max-width: 3.5em;
          }
        }
      }
      .social_flex {
        .social_icon {
          max-width: 3.5em;
          margin-right: 2em;
          cursor: pointer;
          img {
            max-width: 3.5em;
          }
        }
      }
    }
    .box_content_wrapper {
      display: none;
    }
    .ft_logo {
      padding: 0;
      .certificate {
        align-items: center;
        justify-content: space-around;
      }
    }
    .ft_content {
      padding: 0;
    }
    .box_content_active.box_content_wrapper {
      display: block;
    }
    .plus_span {
      padding-left: 0.5em;
      font-size: 1.3em;
    }
    .social_img {
      max-width: 2.4em;
    }
    .footer_logo {
      max-width: 20em;
    }
    .col-lg-3:nth-child(n + 2) {
      margin-top: 3.2em;
    }
    .box_title,
    .footer_contact_title {
      @include textStyles(1.6em, 1.188em, 0.8px);
    }
    .link_name,
    .footer_contact_desc {
      @include textStyles(1.3em, 1.923em, 0.65px);
    }
    .social_links_wrapper {
      margin-top: 2.9em;
      margin-bottom: 0.7em;
    }
    .footer_second_row {
      margin-bottom: 1.5em;
      padding-top: 3em;
      .copyright_text {
        margin-top: 1em;
        text-align: left;
        font-size: 0.9em;
        padding: 0;
        font-size: 1.6em;
        text-align: center;
      }
      .second_row_content {
        @include textStyles(1em, 1.182em, 0.55px);
      }
    }
    .projects {
      .accordion-header {
        position: relative;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 0.4em;
        .accordion-button .icon {
          position: absolute;
          right: 0;
          bottom: 40%;
          width: 100%;
          max-width: 17px;
          transform: rotate(180deg);
        }
        .accordion-button.collapsed {
          .icon {
            transform: rotate(0deg);
          }
        }
        .accordion-button {
          width: 90%;
          display: flex;
          align-items: center;
          width: 100%;
          font-family: "PT Sans", sans-serif;
          font-size: 17px;
          font-weight: 500;
          text-align: left;
          letter-spacing: 0.48px;
          background: transparent;
          border: none;

          p {
            font-family: "PT Sans", sans-serif;
            font-size: 1em;
            font-weight: 600;
            color: $color-FFFFFF;
          }
        }
      }
      .accordion-body {
        width: 90%;
        text-align: left;
        font-size: 14px;
        letter-spacing: 0.4px;
        ul {
          li {
            a {
              color: $color-FFFFFF;
              font-size: 1.1em;
            }
            a:hover {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
// @media(max-width: 834px) {}
// @media(max-width: 767px) {}
// @media(max-width: 375px) {}
// @media(max-width: 320px) {}
/*================================
Ipad/Mobile CSS Ends Here   
================================*/
