@import "../../common.scss";

.hero_banner {
  position: relative;
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  max-height: 100vh;

  .overlay {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-000000;
    width: 100%;
    height: 100%;
    // min-height: 100vh;
    max-height: 100vh;
    opacity: 0.3;
    z-index: 2;
  }
  .hero_img {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100vh;
  }
  .text_container {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-FFFFFF;
    z-index: 4;
    .title {
      @include textStyles(5em, 1.2em, -1px);
      font-family: "PT Sans", sans-serif;
      font-weight: 600;
      text-align: center;
    }
    .desc {
      @include textStyles(2.5em, 1.5em, 0.24px);
      font-family: "Maven Pro", sans-serif;
      font-weight: 500;
      margin-top: 1em;
    }
  }
}

.home_sec2 {
  background-color: $color-FAF9F6;
  .manu_wrap {
    // background-color: $color-0099FF;
    padding: 4em 0;
    .manufacturing_title {
      font-family: "PT Sans", sans-serif;
      font-size: 5em;
      color: $color-0099FF;
      text-align: center;
      font-weight: bold;
    }
  }
  .about_us_card {
    padding: 0 0 4em 0;
    .about_row {
      align-items: flex-start;
      padding: 0 15px;
      .about_img {
        width: 100%;
        border-radius: 0.5em;
      }
      .text_container {
        padding: 0 5em 0;
        .title {
          @include textStyles(3.5em, 1.2em, -1px);
          font-weight: 600;
          max-width: 85%;
          font-family: "PT Sans", sans-serif;
        }
        .description {
          @include textStyles(1.5em, 1.5em, 0.24px);
          margin-top: 2em;
        }
      }
    }
  }
}

.home_sec3 {
  padding: 4em 0 1em;
  .text_container {
    text-align: center;
    margin-bottom: 4em;
    .title {
      @include textStyles(3.5em, 1.2em, 0);
      font-weight: 600;
      color: $color-000000;
      text-transform: capitalize;
      margin: 0em 0 0.25em;
      font-family: "PT Sans", sans-serif;
    }
    .description {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em auto;
      max-width: 70%;
    }
    .list_desc {
      text-align: left;
      margin: 1em auto;
      max-width: 100%;
    }
    .list_desc2 {
      max-width: 100%;
    }
    ul {
      margin: 0.5em 0 0;
      -moz-column-count: 3;
      -webkit-column-count: 3;
      column-count: 3;
      -moz-column-gap: 15px;
      -webkit-column-gap: 15px;
      column-gap: 15px;
      li {
        list-style-type: disc;
        margin: 0.5em 0 0 1em;
      }
    }
  }
  .featured_products_wrapper {
    width: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    .featured_prod_box {
      background-color: $color-FFFFFF;
      .product_img {
        width: 100%;
        max-width: 100%;
        position: relative;
        transform: scale(1);
        transition: 0.3s ease-in-out;
        // filter: blur(2px);
      }
      .imageanimate {
        overflow: hidden;
        border-radius: 5px;
        &:hover {
          .product_img {
            transform: scale(1.2);
            // filter: blur(0);
          }
        }
      }
      .text_container {
        max-width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        .content_box {
          .product_name {
            @include textStyles(2.5em, 1.2em, 0.24px);
            font-weight: 600;
            color: $color-000000;
            text-transform: capitalize;
            margin: 1em 0 0.25em;
            font-family: "PT Sans", sans-serif;
          }
          .product_desc {
            @include textStyles(1.5em, 1.5em, 0.24px);
          }
        }
        .cta_wrapper {
          max-width: 100%;
        }
      }
    }
  }
}

.home_sec4 {
  padding: 2em 0 4em;
  background-color: $color-FAF9F6;
  .text_container {
    text-align: center;
    .title {
      @include textStyles(3.5em, 1.2em, 0);
      font-weight: 600;
      color: $color-000000;
      text-transform: capitalize;
      margin: 0.75em 0 0.25em;
      font-family: "PT Sans", sans-serif;
    }
    .description {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em auto;
      max-width: 80%;
    }
  }
  .secret_box {
    margin: 2em 0;
    padding: 2em 1em;
    // min-height: 27.5em;
    border-radius: 15px;
    .text_container {
      text-align: left;
      .title {
        @include textStyles(2em, normal, 0);
        font-weight: 600;
        color: $color-000000;
        text-transform: capitalize;
        margin: 0.75em 0 0.25em;
        font-family: "PT Sans", sans-serif;
      }
      .description {
        @include textStyles(1.5em, 1.5em, 0.24px);
        margin: 1em 0;
        max-width: 100%;
      }
    }
  }
  .cta_wrapper {
    margin: 0 auto;
  }
}

.home_sec5 {
  padding: 6em 0;
  .text_container {
    width: 100%;
    height: 100%;
    background: url(../../images/Homepage/home-background.webp);
    background: url(../../images/Homepage/home-background.jpg);
    background-size: cover;
    padding: 7.813em 2.5em 3.938em;
    .subtitle {
      @include textStyles(1.5em, 1.2em, 0);
      font-weight: 600;
      color: $color-FFFFFF;
      text-transform: uppercase;
      margin: 0 0 0.25em;
      font-family: "PT Sans", sans-serif;
      vertical-align: middle;
    }
    .title {
      @include textStyles(3.5em, 1.2em, 0);
      font-weight: 600;
      color: $color-FFFFFF;
      text-transform: capitalize;
      margin: 0.25em 0 0.5em;
      font-family: "PT Sans", sans-serif;
    }
    .desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em 0;
      color: $color-FFFFFF;
      max-width: 80%;
    }
  }

  .segment_wrapper {
    width: 100%;
    height: 100%;
    background: $color-0099FF;
    padding: 4em 1.5em;
    .segement_list {
      display: flex;
      flex-wrap: wrap;
      .segment_box {
        width: 100%;
        max-width: 20%;
        padding: 2em 1em;
        text-align: center;
        .segment_img {
          width: 100%;
          max-width: 60%;
          margin: 0 auto;
          padding: 0.938em;
          border: 2px solid $color-FFFFFF;
          border-radius: 5px;
          // height: 5.625em;
          // width: 5.625em;
        }
        .title {
          @include textStyles(1.5em, 1.5em, 0.24px);
          color: $color-FFFFFF;
          margin: 0.75em 0 0;
          text-align: center;
        }
      }
    }
  }
}

.home_sec6 {
  padding: 3em 0 6em;
  background-color: $color-FAF9F6;
  .text_container {
    text-align: center;
    margin-bottom: 4em;
    .title {
      @include textStyles(3.5em, 1.2em, 0);
      font-weight: 600;
      color: $color-000000;
      text-transform: capitalize;
      margin: 0em 0 0.25em;
      font-family: "PT Sans", sans-serif;
    }
    .description {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em auto;
      max-width: 70%;
    }
  }
  .gallery_img {
    width: 100%;
    border-radius: 3px;
    border-radius: 0.5em;
    margin: 0 0 2em;
    cursor: pointer;
  }
  .gallery_arrows_wrapper {
    position: relative;
    margin: auto;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    .left_arrow,
    .right_arrow {
      margin: 1.8em 0.5em 0;
      width: 100%;
      display: block;
      max-width: 4.875em;
      max-height: 4.875em;
      object-fit: contain;
      background-color: rgba($color: $color-0099FF, $alpha: 0.75);
      cursor: pointer;
      border-radius: 50%;
      z-index: 1;
    }
  }
}

@media (max-width: 992px) {
  .hero_banner {
    .hero_img {
      // min-height: 83.5vh;
      object-fit: cover;
    }
    .text_container {
      .desc {
        font-size: 3em;
      }
    }
  }

  .home_sec2 {
    .about_us_card {
      .about_row {
        .text_container {
          .description {
            font-size: 1.8em;
          }
        }
      }
    }
  }

  .home_sec3 {
    .text_container {
      .description {
        font-size: 1.8em;
      }
      ul {
        -moz-column-count: 2;
        -webkit-column-count: 2;
        column-count: 2;
      }
    }
    .featured_products_wrapper {
      .featured_prod_box {
        .text_container {
          text-align: left;
          .content_box {
            .product_desc {
              font-size: 1.8em;
            }
          }
        }
      }
    }
  }

  .home_sec4 {
    .text_container {
      .title {
        font-size: 3.5em;
      }
      .description {
        font-size: 1.8em;
      }
      .cta_wrapper {
        margin: 4em auto 2em;
      }
    }
    .secret_grid {
      .col {
        max-width: 33%;
        flex-basis: 33%;
      }
    }
    .secret_box {
      // min-height: 25em;
      &:hover {
        transform: scale(1);
      }
      .secret_img {
        max-width: 5em;
      }
      .text_container {
        .title {
          font-size: 2em;
        }
        .description {
          font-size: 1.8em;
        }
      }
    }
  }

  .home_sec5 {
    .text_container {
      .subtitle {
        font-size: 2em;
      }
      .desc {
        font-size: 1.8em;
        max-width: 100%;
      }
    }
    .segment_wrapper {
      .segement_list {
        .segment_box {
          max-width: 25%;
          .title {
            font-size: 1.8em;
          }
        }
      }
    }
  }

  .home_sec6 {
    .text_container {
      .title {
        font-size: 3.5em;
      }
      .description {
        font-size: 1.8em;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .home_sec2 {
    .manu_wrap {
      padding: 5em 1.5em;
      .manufacturing_title {
        font-size: 3.5em;
      }
    }
    .about_us_card {
      .about_row {
        .about_img {
          max-width: 100%;
          border-radius: 15px;
          max-height: 30em;
          object-fit: cover;
        }
        .text_container {
          padding: 5em 0 0;
          .title {
            font-size: 2.5em;
            max-width: 100%;
          }
          .description {
            font-size: 1.8em;
            margin-top: 1em;
          }
          .cta_wrapper {
            margin-top: 3em;
          }
        }
      }
    }
  }

  .home_sec3 {
    .text_container {
      text-align: left;
      .title {
        font-size: 3.5em;
      }
      .description {
        max-width: 100%;
      }
      ul {
        -moz-column-count: 1;
        -webkit-column-count: 1;
        column-count: 1;
      }
    }
    .featured_products_wrapper {
      .featured_prod_box {
        .text_container {
          .content_box {
            .product_name {
              font-size: 2.5em;
            }
          }
        }
      }
    }
  }

  .home_sec4 {
    .text_container {
      text-align: left;
      .description {
        max-width: 100%;
      }
      .cta_wrapper {
        margin: 2em 0 0;
      }
    }
  }
  .home_sec6 {
    .text_container {
      text-align: left;
    }
  }
}

@media (max-width: 600px) {
  .hero_banner {
    .hero_img {
      min-height: 70vh;
    }
  }

  .home_sec2 {
    .manu_wrap {
      padding: 4em 1.5em;
    }
    .about_us_card {
      .about_row {
        .text_container {
          padding: 1em 0 0;
        }
      }
    }
  }

  .home_sec4 {
    .secret_grid {
      margin-top: 3em;
      .col {
        max-width: 100%;
        flex-basis: auto;
      }
    }
    .secret_box {
      // min-height: auto;
      margin: 0;
      padding: 3em 1.5em;
      &:hover {
        transform: scale(1);
      }
      .secret_img {
        max-width: 7.5em;
      }
    }
  }

  .home_sec5 {
    .segment_wrapper {
      .segement_list {
        .segment_box {
          max-width: 50%;
          .title {
            font-size: 1.8em;
          }
        }
      }
    }
  }
}
