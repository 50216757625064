@import "../../common.scss";

.about_sec1 {
  padding: 6em 0 0;
  .about_us_card {
    padding: 0 0 6em 0;
    .about_row {
      align-items: flex-start;
      padding: 0 15px;
      .about_img {
        width: 100%;
        border-radius: 0.5em;
      }
      .text_container {
        padding: 0 5em 0 0;
        .title {
          @include textStyles(3.5em, 1.2em, -1px);
          font-weight: 600;
          max-width: 85%;
          font-family: "PT Sans", sans-serif;
        }
        .description {
          @include textStyles(1.5em, 1.5em, 0.24px);
          margin-top: 2em;
        }
      }
    }
  }
  .mission_vision_row {
    .image_wrap {
      text-align: center;
      .icon {
        width: 100%;
        max-width: 10em;
      }
    }
    .text_container {
      padding: 0 0 6em 0;
      text-align: center;
      .title {
        @include textStyles(3.5em, 1.2em, -1px);
        font-weight: 600;
        font-family: "PT Sans", sans-serif;
        margin-top: 0.5em;
      }
      .description {
        @include textStyles(1.5em, 1.5em, 0.24px);
        margin-top: 1em;
      }
    }
  }
  .our_team_wrap {
    padding: 0 15px 6em;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .team_box {
      width: 100%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .icon {
        width: 100%;
        max-width: fit-content;
        margin: 0 auto 2em;
      }
      .title {
        @include textStyles(1.5em, 1.2em, -1px);
        font-weight: 600;
        font-family: "PT Sans", sans-serif;
        text-align: center;
      }
    }
  }
  .why_choose {
    padding: 0 0 6em;
    .text_container {
      text-align: center;
      .title {
        @include textStyles(3.5em, 1.2em, -1px);
        font-weight: 600;
        font-family: "PT Sans", sans-serif;
        margin: 0 0 1em;
      }
      .desc {
        @include textStyles(1.5em, 1.5em, 0.24px);
        margin-top: 1em;
        font-weight: 400;
      }
    }
    .why_choose_row {
      .choose_box {
        text-align: center;
        padding: 0 1.5em 2em;
        .title {
          @include textStyles(2em, 1.2em, -1px);
          font-weight: 600;
          font-family: "PT Sans", sans-serif;
          margin-top: 0.5em;
        }
        .desc {
          @include textStyles(1.5em, 1.5em, 0.24px);
          margin-top: 1em;
        }
      }
    }
  }
  .faqs_container {
    padding: 0 0 6em;
    .title {
      @include textStyles(3.5em, 1.2em, -1px);
      font-weight: 600;
      font-family: "PT Sans", sans-serif;
      margin: 0 0 1em;
      text-align: center;
    }
    .faq_desc {
      @include textStyles(1.5em, 1.5em, 0.24px);
      margin: 1em 0;
      margin: 0 0 0.75em;
    }
  }
}

@media (max-width: 992px) {
  .about_sec1 {
    .about_us_card {
      .about_row {
        .text_container {
          .description {
            font-size: 1.8em;
          }
        }
      }
    }
    .mission_vision_row {
      .text_container {
        .description {
          font-size: 1.8em;
        }
      }
    }
    .faqs_container {
      .faq_desc {
        font-size: 1.8em;
      }
    }
  }
}

@media (max-width: 767px) {
  .about_sec1 {
    .about_us_card {
      .about_row {
        .about_img {
          max-width: 100%;
          border-radius: 15px;
          max-height: 30em;
          object-fit: cover;
        }
        .text_container {
          padding: 5em 0 0;
          .title {
            font-size: 4em;
            max-width: 100%;
            text-align: center;
            margin: 0.5em 0 0;
          }
          .description {
            font-size: 1.8em;
            margin-top: 1em;
          }
          .cta_wrapper {
            margin: 3em auto 0;
          }
        }
      }
    }
    .mission_vision_row {
      .about_img {
        max-width: 100%;
        border-radius: 15px;
        max-height: 30em;
        object-fit: cover;
      }
      .text_container {
        padding: 5em 0 0;
        .title {
          font-size: 4em;
          max-width: 100%;
          text-align: center;
          margin: 0.5em 0 0;
        }
        .description {
          font-size: 1.8em;
          margin-top: 1em;
        }
        .cta_wrapper {
          margin: 3em auto 0;
        }
      }
    }
    .our_team_wrap {
      padding: 0 15px;
      .team_box {
        .title {
          font-size: 4em;
          max-width: 100%;
          text-align: center;
          margin: 0.5em 0 0;
        }
      }
    }
    .why_choose {
      .text_container {
        .desc {
          font-size: 1.8em;
          margin-top: 1em;
        }
      }
      .why_choose_row {
        .choose_box {
          .title {
            font-size: 2em;
            max-width: 100%;
            text-align: center;
            margin: 0 0 0.5em;
          }
          .desc {
            font-size: 1.8em;
            margin-top: 1em;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .about_sec1 {
    .manu_wrap {
      padding: 4em 1.5em;
    }
    .about_us_card {
      .about_row {
        .text_container {
          padding: 1em 0 0;
        }
      }
    }
    .our_team_wrap {
      margin: 2em 0;
      .team_box {
        max-width: 33%;
        margin: 0 0 2em;
        .icon {
          max-width: 4em;
          margin: 0 auto 0.25em;
        }
        .title {
          font-size: 1.6em;
        }
      }
    }
  }
}
